import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {

  title: string
  closeBtnName: string
  texto: string
  callback = null

  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
  }

  ok() {
    if (this.callback) {
      this.callback()
    }
    this.bsModalRef.hide()
  }

}
