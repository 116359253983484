import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { pgCardModule } from '../@pages/components/card/card.module';
import { TextMaskModule } from 'angular2-text-mask';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../@pages/components/shared.module';
import { pgUploadModule } from '../@pages/components/upload/upload.module';
import { ModalModule } from 'ngx-bootstrap';

@NgModule({
  imports: [
    CommonModule,
    pgCardModule,
    TextMaskModule,
    FormsModule, ReactiveFormsModule, 
    SharedModule,
    pgUploadModule,
    ModalModule.forRoot()
  ],
  declarations: [ConfirmDialogComponent],
  entryComponents: [ConfirmDialogComponent],
  exports: [ConfirmDialogComponent]
})
export class UtilsModule { }
