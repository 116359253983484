import { AbstractControl } from '@angular/forms';
 
export class PasswordValidation {
    static matchPassword(ac: AbstractControl) {
        const pwd = ac.get('senha');
        const cnfpwd = ac.get('confirmacaoSenha');
        if (pwd.value === cnfpwd.value) {
            return null;
        }
 
        ac.get('confirmacaoSenha').setErrors({ mustMatch: true });
        return true;
    }
}